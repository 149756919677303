import * as d3 from 'd3';

/**
 * Pie Chart (Business) Entry
 */
export class PieChartEntry {

  categoryLabel: string;

  constructor(public categoryId: any,
              public value: number,
              public index: number) {
  }

  add(value: number) {
    this.value += value;
  }

} // PieChartEntry


/**
 * D3 Individual Pie Arc
 */
export class PieChartArc {

  /**
   * Pie Chart Arc
   * @param label display label
   * @param name data name
   * @param value data value
   * @param entry entry index
   */
  constructor(public label: string, public name: string, public value: number, public entry: number) {
  }

  valueOf(): number {
    return this.value;
  }

} // PieChartArc


/**
 * Pie Chart Data
 */
export class PieChartData {

  // Chart Label - e.g. expenses
  label: string = 'PieChart';
  // Sub Label - e.g. for week
  subLabel: string = '';
  // Value dimension - e.g. $
  dimensionLabelPrefix: string = '';
  // Value dimension - e.g. h
  dimensionLabelSuffix: string = '';
  /** value d3 formatter (fixed 2 decimals) */
  valueFormat: string = '.2f';

  totalLabel: string = 'Total: ';

  // individual arcs
  data: PieChartArc[] = [];

  /** categories */
  categoryIds: string[] = [ '-' ]; // initial empty
  categoryLabels: string[] = [ '-' ]; // initial empty

  /** Map key-> entry */
  private entryMap: Map<number, PieChartEntry> = new Map<number, PieChartEntry>();

  // total value
  get totalValue(): number {
    return this.data.reduce((sum: number, d: PieChartArc) => {
      return sum + d.valueOf();
    }, 0);
  }

  add(categoryId: any, value: number) {
    let categoryIndex: number = 0;
    if (categoryId) {
      categoryIndex = this.categoryIds.indexOf(categoryId);
      if (categoryIndex === -1) {
        this.categoryIds.push(categoryId);
        categoryIndex = this.categoryIds.indexOf(categoryId);
      }
    }
    //
    const key = categoryIndex;
    let entry: PieChartEntry = this.entryMap.get(key);
    if (entry) {
      entry.add(value);
    } else {
      entry = new PieChartEntry(categoryId, value, key);
      this.entryMap.set(key, entry);
    }
  } // add

  /**
   * Reset Data
   */
  clear() {
    this.data = [];
    this.categoryIds = [ '-' ];
    this.categoryLabels = [ '-None-' ];
  }

  /**
   * Add Labels
   */
  process(idLabelMap: Map<string, string>) {
    this.data = [];

    const entries: PieChartEntry[] = [];
    this.entryMap.forEach((value: PieChartEntry) => {
      if (idLabelMap) { // update poe chart entry
        if (value.categoryId) {
          const categoryLabel = idLabelMap[ value.categoryId ];
          if (categoryLabel) {
            value.categoryLabel = categoryLabel;
          }
        } else {
          value.categoryLabel = '-None-';
        }
      }
      if (!value.categoryLabel) {
        value.categoryLabel = '<' + value.categoryId + '>';
      }
      entries.push(value);
    });

    // category labels
    if (idLabelMap) {
      for (let i = 1; i < this.categoryIds.length; i++) {
        if (this.categoryLabels.length < (i - 1)) {
          this.categoryLabels.push(idLabelMap[ this.categoryIds[ i ] ]);
        } else {
          this.categoryLabels[ i ] = idLabelMap[ this.categoryIds[ i ] ];
        }
      }
    }

    for (const entry of entries) {
      const categoryIndex = this.categoryIds.indexOf(entry.categoryId || '') + 1;
      this.data.push(new PieChartArc(
        entry.categoryLabel,
        entry.categoryId,
        entry.value,
        entry.index));
    }
  } // process

  valueLabel(value: number): string {
    return this.dimensionLabelPrefix + d3.format(this.valueFormat)(value) + this.dimensionLabelSuffix;
  }

} // PieChartData
