import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';

import * as d3 from 'd3';
import { BarChartStackedData, BarChartStackedDatum } from './bar-chart-stacked.data';
import { Logger } from 'accorto';

/**
 * Stacked Bar Chart based on time
 * https://github.com/d3/d3-shape/blob/master/README.md#stacks
 */
@Component({
  selector: 't4d-bar-chart-stacked',
  templateUrl: './bar-chart-stacked.component.html',
  styleUrls: [ './bar-chart-stacked.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class BarChartStackedComponent implements OnChanges {

  /** The Chart Data */
  @Input() cd: BarChartStackedData = new BarChartStackedData();
  /** Svg Width */
  @Input() width: number = 600;
  /** Svg Height */
  @Input() height: number = 300;

  /** div to contain svg */
  @ViewChild('chart', { static: true }) chartContainer: ElementRef;
  /** div to contain svg */
  @ViewChild('checkbox', { static: true }) checkbox: ElementRef;

  isNoData: boolean = true;

  private margin = { top: 20, right: 20, bottom: 30, left: 40 };

  private contentWidth: number;
  private contentHeight: number;

  private svg: d3.Selection<any, any, any, any>;
  private g: d3.Selection<any, any, any, any>;
  private xScale: d3.ScaleBand<string>;
  private yScale: d3.ScaleLinear<number, number>;
  private zScale: any;

  private y0: d3.ScaleBand<string>;
  private y1: d3.ScaleLinear<number, number>;
  private dataByGroup: Array<{ key: string; values: Array<BarChartStackedDatum>; value: any | undefined }>;

  /** Logger */
  private log: Logger = new Logger('BarChartStacked');

  constructor() {
    /* Test Data
    this.cd.subLabel = 'Example';
    this.cd.dimensionLabelPrefix = '$';
    this.cd.isNoData = false;

    this.cd.add(new Date(2017, 1, 10), 'c1', 1);
    this.cd.add(new Date(2017, 2, 10), 'c1', 2);
    this.cd.add(new Date(2017, 3, 10), 'c1', 3);
    this.cd.add(new Date(2017, 1, 10), 'c1', 10);

    this.cd.add(new Date(2017, 1, 10), 'c2', 11);
    this.cd.add(new Date(2017, 2, 10), 'c2', 12);
    this.cd.add(new Date(2017, 3, 10), 'c2', 13);

    this.cd.add(new Date(2017, 1, 10), 'c3', 5);
    this.cd.add(new Date(2017, 2, 10), 'c3', 7);
    this.cd.add(new Date(2017, 3, 10), 'c3', 7);
    /* */
  }

  ngOnChanges(changes: SimpleChanges) {
    this.log.info('ngOnChanges', this.cd)();
    this.isNoData = this.cd.data.length === 0;
    this.initSvg();
    this.initScale();
    this.update();
  }

  //  https://jsfiddle.net/levvsha/vrbq7ebz/

  // https://github.com/d3/d3-shape/blob/master/README.md#stacks

  // https://bl.ocks.org/mjfoster83/7c9bdfd714ab2f2e39dd5c09057a55a0
  // https://bl.ocks.org/DimsumPanda/689368252f55179e12185e13c5ed1fee
  // http://www.adeveloperdiary.com/d3-js/create-stacked-bar-chart-using-d3-js/

  /**
   * Layout Change
   */
  onLayoutChange(evt: Event) {
    const element = this.checkbox.nativeElement;
    // this.log.log('onLayoutChange', element.checked)();
    if (element.checked) {
      this.transitionStacked();
    } else {
      this.transitionLayered();
    }
  } // onLayoutChange

  /**
   * Layered Layout
   */
  transitionLayered() {
    const t = this.svg.transition().duration(750);

    const g = t.selectAll('.group')
      .attr('transform', (dataBy: { key: string; values: any; value: Array<BarChartStackedDatum> | undefined }) => {
        return 'translate(0,' + this.y0(dataBy.key) + ')';
      });
    g.selectAll('rect').attr('y', (d: BarChartStackedDatum) => {
      return this.y1(d.value);
    });
    // y label
    g.select('.group-label')
      .attr('y', (dataGroup: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }) => {
        const yy: number = dataGroup.values[ 0 ].value / 2; // center y
        // this.log.log('group-label (layered)', dataGroup, yy)();
        return this.y1(dataGroup.values[ 0 ].value / 2);
      });

    g.select('.group-total')
      .attr('y', (dataGroup: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }) => {
        return this.y1(dataGroup.values[ 0 ].value / 2);
      });
  } // transitionLayered

  /**
   * Stacked Layout
   */
  transitionStacked() {
    const t = this.svg.transition().duration(750);
    const g = t.selectAll('.group')
      .attr('transform', 'translate(0,' + this.y0(this.y0.domain()[ 0 ]) + ')');

    // this.log.log('transitionStacked categories' + this.cd.categoryIds)();
    g.selectAll('rect')
      .attr('y', (d: BarChartStackedDatum, i) => {
        // this.log.log('transitionStacked d', d)();
        // values - e.g. [10, 11]
        const dataSet: number[] = this.dataByGroup.map((dataItem) => {
          if (dataItem.values[ i ]) {
            return dataItem.values[ i ].value;
          }
          return 0;
        });
        // categoryIds = - e.g. [0, 2]
        const categorySet: number[] = this.dataByGroup.map((dataItem) => {
          if (dataItem.values[ i ]) {
            return dataItem.values[ i ].category;
          }
          return 0;
        });
        const deleteCount = categorySet.indexOf(d.category) + 1;
        // this.log.log('transitionStacked dataSet i=' + i + ' cat=' + d.category + ' del=' + deleteCount, categorySet, dataSet)();
        const transition = dataSet
          .splice(0, deleteCount)
          .reduce((store, value) => {
            return store + value;
          }, 0);
        // this.log.log('transitionStacked transition', this.y1(transition));
        return this.y1(transition);
      }); // y for rect

    // y label
    const index = this.cd.dateTotals().indexOf(Math.max(...this.cd.dateTotals())); // index of max day
    // this.log.debug('transitionStacked dataTotals i=' + index, this.cd.dateTotals());
    const minDelta = 14; // font space
    let lastY: number = 0;
    g.select('.group-label')
      .attr('y', (dataGroup: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }, i) => {
        const dataSet = this.dataByGroup.map((dataItem) => {
          return dataItem.values[ index ].value;
        });
        const transition = dataSet.splice(0, i).reduce((store, value) => {
          return store + value;
        }, 0);
        // return this.y1(transition + dataGroup.values[ index ].value / 2);
        const thisYY = transition + dataGroup.values[ index ].value / 2; // center y
        const thisY = this.y1(thisYY);
        let deltaY: number = 0;
        const diff = lastY === 0 ? thisY : -(thisY - lastY);
        if (diff < minDelta) {
          deltaY = diff - minDelta;
        }
        // const yy: number = dataGroup.values[ index ].value / 2; // center y
        // this.log.log('group-label (stacked) #' + i + ' for ' + index, dataGroup, dataSet, 'y=' + yy + '+' + transition
        // + '=' + thisY + '(' + thisY + '+' + deltaY + '|' + diff + ')')();
        lastY = thisY + deltaY;
        return thisY + deltaY;
      }); // y for label
    // y label total
    lastY = 0;
    g.select('.group-total')
      .attr('y', (dataGroup: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }, i) => {
        const dataSet = this.dataByGroup.map((dataItem) => {
          return dataItem.values[ index ].value;
        });
        const transition = dataSet.splice(0, i).reduce((store, value) => {
          return store + value;
        }, 0);
        const thisYY = transition + dataGroup.values[ index ].value / 2; // center y
        const thisY = this.y1(thisYY);
        let deltaY: number = 0;
        const diff = lastY === 0 ? thisY : -(thisY - lastY);
        if (diff < minDelta) {
          deltaY = diff - minDelta;
        }
        lastY = thisY + deltaY;
        return thisY + deltaY;
      }); // y for label
  } // transitionStacked

  updateTooltip(tooltip, d) {
    const g = this.g.node() as SVGGElement;
    const mouseXy = d3.mouse(g);
    const xPosition = mouseXy[ 0 ] + 20; // more center
    const yPosition = mouseXy[ 1 ] - 2; // above (otherwise fires mouse-out/leave)
    const text = this.cd.entryLabel(d.entry, d.value);
    tooltip
      .attr('transform', 'translate(' + xPosition + ',' + yPosition + ')')
      .select('text').text(text);
    // this.log.debug('updateTooltip', mouseXy, d, text + ' (' + xPosition + ',' + yPosition + ')')();
  } // updateTooltip

  private initScale() {
    // x -- time/category
    this.xScale = d3.scaleBand()
      .rangeRound([ 0, this.contentWidth ])
      .paddingInner(.1)
      .align(.1);


    // y -- values
    this.yScale = d3.scaleLinear()
      .rangeRound([ this.contentHeight, 0 ]);

    // z -- color
    this.zScale = d3.scaleOrdinal(d3.schemeCategory10);

  } // initScale

  private initSvg() {
    // http://bl.ocks.org/Caged/6476579
    // https://github.com/keathmilligan/angular2-d3-v4/blob/master/src/app/shared/barchart/barchart.component.ts
    const element = this.chartContainer.nativeElement;
    d3.select(element).select('svg').remove();
    this.svg = d3.select(element).append('svg')
      .attr('width', this.width)
      .attr('height', this.height);
    // console.log('initSvg', this.svg);

    this.contentWidth = this.width - this.margin.left - this.margin.right;
    this.contentHeight = this.height - this.margin.top - this.margin.bottom;
    this.g = this.svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    // this.log.log('initSvg', this.g)();
  } // initSvg

  /**
   * Update Bar Chart
   */
  private update() {
    // layers
    const checkbox = this.checkbox.nativeElement;
    checkbox.checked = false;

    // -- x axis --
    const xScale = d3.scaleBand()
      .rangeRound([ 0, this.contentWidth ])
      .padding(.1);
    const xAxis = d3.axisBottom(xScale)
      .tickFormat((d: string, i: number): string => {
        // this.log.log('tickFormat x ' + i, d)();
        return d; // formatted value
      });
    const xtScale = d3.scaleBand()
      .rangeRound([ 0, this.contentWidth ])
      .padding(.1);
    const xtAxis = d3.axisTop(xtScale)
      .tickFormat((d, i: number): string => {
        // this.log.log('tickFormat xt ' + i, d)();
        return this.cd.dateTotalLabel(+d); // formatted value
      });

    // -- y axis --
    this.y0 = d3.scaleBand()
      .rangeRound([ this.contentHeight, 0 ])
      .padding(.2);
    this.y1 = d3.scaleLinear();

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // data
    const nest = d3.nest()
      .key((d: BarChartStackedDatum) => {
        return this.cd.categoryLabel(d.category);
      });

    // process data
    this.dataByGroup = nest.entries(this.cd.data);
    // this.log.log('dataByGroup', this.dataByGroup)();
    if (this.dataByGroup.length === 0) {
      return; // no data
    }

    // -- x axis domain
    xScale.domain(this.dataByGroup[ 0 ].values.map((d: BarChartStackedDatum) => {
      return this.cd.dateLabel(d.date); // formatted date
    }));
    xtScale.domain(this.dataByGroup[ 0 ].values.map((d: BarChartStackedDatum) => {
      return '' + d.date; // time for total lookup
    }));
    // xtScale.domain(this.cd.dateTotalLabels());

    // -- y axis domanin
    this.y0.domain(this.dataByGroup.map((d) => {
      return d.key;
    }));
    this.y1.domain([ 0, d3.max(this.cd.data, (d) => {
      return d.value;
    }) ])
      .range([ this.y0.bandwidth(), 0 ]);

    // tooltip
    const tooltip = this.svg.append('g')
      .attr('class', 'tooltip')
      .style('display', 'none');
    tooltip.append('rect')
      .attr('width', 100)
      .attr('height', 20)
      .attr('fill', 'white')
      .style('opacity', 0.5);
    tooltip.append('text')
      .attr('x', 5)
      .attr('dy', '1.2em')
      .style('text-anchor', 'start')
      .attr('font-size', 'smaller')
      .attr('font-weight', 'bolder');

    // stack
    const stack = d3.stack()
      .keys(this.cd.keys)(this.cd.data);
    // this.log.info('stack', stack)();

    const group = this.g.selectAll('.group')
      .data(this.dataByGroup)
      .enter().append('g')
      .attr('class', 'group')
      .attr('transform', (dataBy: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }) => {
        // this.log.log('transform d', dataBy)();
        return 'translate(0,' + this.y0(dataBy.key) + ')';
      });

    /* rectangles */
    const rect = group.selectAll('rect')
      .data((dataBy: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }) => {
        // this.log.log('rect d', dataBy)();
        return dataBy.values;
      })
      .enter().append('rect');
    rect.style('fill', (d: BarChartStackedDatum) => {
      return color(this.cd.categoryLabel(d.category));
    })
      .attr('x', (d: BarChartStackedDatum) => {
        return xScale(this.cd.dateLabel(d.date));
      })
      .attr('y', (d: BarChartStackedDatum) => {
        return this.y1(d.value);
      })
      .attr('width', xScale.bandwidth())
      .attr('height', (d: BarChartStackedDatum) => {
        return this.y0.bandwidth() - this.y1(d.value);
      });
    rect.on('click', (d: BarChartStackedDatum) => {
      // this.log.info('rect-click')();
      tooltip.style('display', null); // show
      this.updateTooltip(tooltip, d);
    })
    /* .on('mouseover', (d) => {
      // this.log.info('rect-mouse-over')();
      // tooltip.style('display', null);
    })
    .on('mouseout', (d) => {
      // this.log.info('rect-mouse-out')();
      // tooltip.style('display', 'none');
    }) */
      .on('mouseenter', (d) => { // no bubble
        // this.log.info('rect-mouse-enter')();
        tooltip.style('display', null);
      })
      .on('mouseleave', (d) => { // no bubble
        // this.log.info('rect-mouse-leave')();
        tooltip.style('display', 'none');
      })
      .on('mousemove', (d) => {
        // this.log.info('rect-mouse-move')();
        // tooltip.style('display', null);
        this.updateTooltip(tooltip, d);
      });
    /**/

    // y axis label
    group.append('text')
      .attr('class', 'group-label')
      .attr('x', 15 - this.margin.left)
      .attr('y', (dataGroup: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }) => {
        const yy: number = dataGroup.values[ 0 ].value / 2; // center y
        // this.log.log('group-label (update)', dataGroup, yy)();
        return this.y1(yy); // center y
      })
      // .attr('dy', '.35em') // push lower
      .text((dataGroup) => { // { key: values: }
        // this.log.log('group-label dataGroup', dataGroup)();
        return dataGroup.key; // the name
      });

    // y axis total
    group.append('text')
      .attr('class', 'group-total')
      .attr('x', (dataBy: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }) => {
        return this.contentWidth; // text-anchor: end
      })
      .attr('y', (dataBy: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }) => {
        return this.y1(dataBy.values[ 0 ].value / 2); // center y
      })
      // .attr('dy', '.35em') // push lower
      .attr('dx', '.35em') // push right
      .text((dataBy: { key: string; values: Array<BarChartStackedDatum>; value: any | undefined }) => {
        const sum = dataBy.values.reduce((store, value) => {
          return store + value.value;
        }, 0);
        return this.cd.valueLabel(sum);
      });

    // create x axis
    group.filter((dataBy, i) => {
      return !i;
    }).append('g')
      .attr('class', 'axis x')
      .attr('transform', 'translate(0,' + this.y0.bandwidth() + ')')
      .call(xAxis);
    this.g.append('g')
      .attr('class', 'axis xt')
      .call(xtAxis);
    /**/

    // switch to stacked
    setTimeout(() => {
      checkbox.checked = true;
      this.transitionStacked();
    }, 4000);
  } // update

} // BarChartStackedComponent
