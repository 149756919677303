/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bar-chart-stacked.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./bar-chart-stacked.component";
var styles_BarChartStackedComponent = [i0.styles];
var RenderType_BarChartStackedComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BarChartStackedComponent, data: {} });
export { RenderType_BarChartStackedComponent as RenderType_BarChartStackedComponent };
function View_BarChartStackedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "slds-m-left_small slds-text-heading_label"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cd.subLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_BarChartStackedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "slds-p-top_small slds-p-left_small slds-text-color_weak"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No data (yet)\n"]))], null, null); }
export function View_BarChartStackedComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { chartContainer: 0 }), i1.ɵqud(402653184, 2, { checkbox: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "slds-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "slds-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "slds-m-top_small slds-m-left_small slds-text-heading_medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BarChartStackedComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "slds-col slds-align-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 10, "div", [["class", "slds-form-element"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 9, "label", [["class", "slds-checkbox_toggle slds-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "slds-form-element__label slds-m-bottom_none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Layout"])), (_l()(), i1.ɵeld(13, 0, [[2, 0], ["checkbox", 1]], null, 0, "input", [["aria-describedby", "toggle-desc"], ["name", "layout"], ["type", "checkbox"], ["value", "on"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onLayoutChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 5, "span", [["aria-live", "assertive"], ["class", "slds-checkbox_faux_container"], ["id", "toggle-desc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "slds-checkbox_faux"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "slds-checkbox_on"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Stacked"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "slds-checkbox_off"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Layers"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BarChartStackedComponent_2)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, [[1, 0], ["chart", 1]], null, 0, "div", [["class", "bar-chart-stacked"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.cd.subLabel; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.isNoData; _ck(_v, 21, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cd.label; _ck(_v, 5, 0, currVal_0); }); }
export function View_BarChartStackedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "t4d-bar-chart-stacked", [], null, null, null, View_BarChartStackedComponent_0, RenderType_BarChartStackedComponent)), i1.ɵdid(1, 573440, null, 0, i3.BarChartStackedComponent, [], null, null)], null, null); }
var BarChartStackedComponentNgFactory = i1.ɵccf("t4d-bar-chart-stacked", i3.BarChartStackedComponent, View_BarChartStackedComponent_Host_0, { cd: "cd", width: "width", height: "height" }, {}, []);
export { BarChartStackedComponentNgFactory as BarChartStackedComponentNgFactory };
