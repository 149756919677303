import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { selectUi, uiRequestAction, UiTab } from 'accorto';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UiTeitemResolver implements Resolve<UiTab> {

  constructor(private store: Store<any>) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<UiTab> | Promise<UiTab> | UiTab {
    const uiName = 'TEItem';

    return this.store
      .pipe(
        select(selectUi(uiName)),
        tap(uiTab => {
          if (!uiTab) {
            this.store.dispatch(uiRequestAction({ uiName }));
          }
        }),
        filter(uiTab => !!uiTab),
        first()
      );
  } // resolve

} // UiTeitemResolver
