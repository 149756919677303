import { selectUi, uiRequestAction } from 'accorto';
import { select, Store } from '@ngrx/store';
import { filter, first, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class UiTeitemResolver {
    constructor(store) {
        this.store = store;
    }
    resolve(route, state) {
        const uiName = 'TEItem';
        return this.store
            .pipe(select(selectUi(uiName)), tap(uiTab => {
            if (!uiTab) {
                this.store.dispatch(uiRequestAction({ uiName }));
            }
        }), filter(uiTab => !!uiTab), first());
    } // resolve
} // UiTeitemResolver
/** @nocollapse */ UiTeitemResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiTeitemResolver_Factory() { return new UiTeitemResolver(i0.ɵɵinject(i1.Store)); }, token: UiTeitemResolver, providedIn: "root" });
