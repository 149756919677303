import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataResolver, LoginGuard, ObjectHomeComponent, RecordHomeComponent, StatusComponent, UiResolver } from 'accorto';
import { TimeComponent } from './time/time.component';
import { ExpenseComponent } from './expense/expense.component';
import { TimeKeeperComponent } from './time-keeper/time-keeper.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { LoginDeactivateGuard } from './login/login-deactivate.guard';
import { UiTeitemResolver } from './dashboard/ui.teitem.resolver';
import { TestComponent } from './test/test.component';
import { InOutComponent } from './in-out/in-out.component';
import { ExpenseTableComponent } from './expense-table/expense-table.component';
import { PerDiemComponent } from './per-diem/per-diem.component';
import { PerDiemDeComponent } from './per-diem-de/per-diem-de.component';

const routes: Routes = [
  {
    path: 'test',
    component: TestComponent
  },

  {
    path: 'login',
    component: LoginComponent,
    canDeactivate: [ LoginDeactivateGuard ],
    data: {
      title: 'Login',
      description: 'Please provide credentials',
      keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
    }
  },
  {
    path: 'time',
    component: TimeComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiTeitemResolver },
    data: {
      title: 'Capture Time',
      description: 'Enter Time',
      keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
    }
  },
  {
    path: 'in-out',
    component: InOutComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiTeitemResolver },
    data: {
      title: 'Check In/Out',
      description: 'Check/Clock In and Out',
      keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
    }
  },
  {
    path: 'expense',
    component: ExpenseComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiTeitemResolver },
    data: {
      title: 'Capture Expenses',
      description: 'Enter Expenses',
      keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
    }
  },
  {
    path: 'per-diem',
    component: PerDiemComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiTeitemResolver },
    data: {
      title: 'Capture Per Diem',
      description: 'Enter Per Diem',
      keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
    }
  },
  {
    path: 'per-diem-de',
    component: PerDiemDeComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiTeitemResolver },
    data: {
      title: 'Tagessaetze',
      description: 'Tagessaetze',
      keywords: ''
    }
  },
  {
    path: 'expense-table',
    component: ExpenseTableComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiTeitemResolver },
    data: {
      title: 'Expense Table',
      description: 'Manage Expenses',
      keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
    }
  },
  {
    path: 'track',
    component: TimeKeeperComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiTeitemResolver },
    data: {
      title: 'Timekeeper',
      description: 'Track Time',
      keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
    }
  },

  {
    path: 'timesheet',
    component: TimesheetComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiTeitemResolver },
    data: {
      title: 'Weekly Timesheet',
      description: 'Timesheet for weekly time',
      keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
    }
  },

  {
    path: 'ui/:uiTab',
    component: ObjectHomeComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiResolver }    // route.params[ 'uiTab' ];
  },
  {
    path: 'ui/:uiTab/:id',
    component: RecordHomeComponent,
    canActivate: [ LoginGuard ],
    resolve: {
      uiTab: UiResolver,    // route.params[ 'uiTab' ];
      record: DataResolver  // route.params[ 'id' ];
    }
  },

  {
    path: 'status',
    component: StatusComponent,
    data: {
      title: 'Status',
      description: 'Client Status and settings',
      keywords: ''
    }
  },

  {
    path: '',
    component: DashboardComponent,
    canActivate: [ LoginGuard ],
    resolve: { uiTab: UiTeitemResolver },
    data: {
      title: 'Welcome',
      description: 'Time + Expense Overview',
      keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock in/out, Check in/out'
    }
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
