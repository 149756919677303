import { select, Store } from '@ngrx/store';
import { selectLoginLoggedIn, uiRequestAction } from 'accorto';
import { trackItemRequest } from '../track-item/track-item.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Submit Track Request after successful Login
 */
export class LoginDeactivateGuard {
    constructor(store) {
        this.store = store;
        /** Logged In */
        this.loggedIn = false;
        this.store.pipe(select(selectLoginLoggedIn))
            .subscribe((val) => {
            this.loggedIn = val;
        });
    }
    canDeactivate(component, currentRoute, currentState, nextState) {
        if (this.loggedIn) {
            this.store.dispatch(trackItemRequest({ startTimeMs: 0 }));
            this.store.dispatch(uiRequestAction({ uiName: 'TEItem' }));
        }
        return true;
    } // canDeactivate
} // LoginDeactivateGuard
/** @nocollapse */ LoginDeactivateGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginDeactivateGuard_Factory() { return new LoginDeactivateGuard(i0.ɵɵinject(i1.Store)); }, token: LoginDeactivateGuard, providedIn: "root" });
