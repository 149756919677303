import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { LoginComponent } from './login.component';
import { selectLoginLoggedIn, uiRequestAction } from 'accorto';
import { trackItemRequest } from '../track-item/track-item.actions';

/**
 * Submit Track Request after successful Login
 */
@Injectable({
  providedIn: 'root'
})
export class LoginDeactivateGuard implements CanDeactivate<LoginComponent> {

  /** Logged In */
  private loggedIn: boolean = false;

  constructor(private store: Store<any>) {
    this.store.pipe(select(selectLoginLoggedIn))
      .subscribe((val) => {
        this.loggedIn = val;
    });
  }


  public canDeactivate(component: LoginComponent,
                       currentRoute: ActivatedRouteSnapshot,
                       currentState: RouterStateSnapshot,
                       nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.loggedIn) {
      this.store.dispatch(trackItemRequest({ startTimeMs: 0 }));
      this.store.dispatch(uiRequestAction({ uiName: 'TEItem' }));
    }
    return true;
  } // canDeactivate

} // LoginDeactivateGuard
