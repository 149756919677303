import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';

import { trackItemError, trackItemRequest, trackItemResponse } from './track-item.actions';
import { TrackItemService } from './track-item.service';
import { NotificationService } from 'accorto';
import { AppState } from '../reducers';


@Injectable()
export class TrackItemEffects {

  // send track query request
  trackRequest$ = createEffect(() => this.actions$.pipe(
    ofType(trackItemRequest),
    // tap((action) => {
    //   console.log('trackRequest$', action);
    // }),
    concatMap((action) => this.service.query(action.startTimeMs, 'trackRequest$')
      .pipe(
        catchError(err => {
          console.log('trackRequest$.error', err);
          this.store.dispatch(trackItemError({ err }));
          return EMPTY;
        })
      )
    ),
    tap((response) => {
      if (response.error) {
        console.log('trackRequest$.response', response);
        this.notify.addError(response.error, response.message);
      }
    }),
    map(response => trackItemResponse({ response }))
    ), {}
  ); // trackRequest$


  trackError$ = createEffect(() => this.actions$.pipe(
    ofType(trackItemError),
    tap((action) => {
      console.warn('trackError$', action);
      this.notify.addException('Connection Error', action.err);
    })
    ), { dispatch: false }
  ); // trackError$


  constructor(private actions$: Actions,
              private service: TrackItemService,
              private store: Store<AppState>,
              private notify: NotificationService) {
  }

} // TrackItemEffects
