import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataResolver, LoginGuard, ObjectHomeComponent, RecordHomeComponent, StatusComponent, UiResolver } from 'accorto';
import { TimeComponent } from './time/time.component';
import { ExpenseComponent } from './expense/expense.component';
import { TimeKeeperComponent } from './time-keeper/time-keeper.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { LoginDeactivateGuard } from './login/login-deactivate.guard';
import { UiTeitemResolver } from './dashboard/ui.teitem.resolver';
import { TestComponent } from './test/test.component';
import { InOutComponent } from './in-out/in-out.component';
import { ExpenseTableComponent } from './expense-table/expense-table.component';
import { PerDiemComponent } from './per-diem/per-diem.component';
import { PerDiemDeComponent } from './per-diem-de/per-diem-de.component';
const ɵ0 = {
    title: 'Login',
    description: 'Please provide credentials',
    keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
}, ɵ1 = {
    title: 'Capture Time',
    description: 'Enter Time',
    keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
}, ɵ2 = {
    title: 'Check In/Out',
    description: 'Check/Clock In and Out',
    keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
}, ɵ3 = {
    title: 'Capture Expenses',
    description: 'Enter Expenses',
    keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
}, ɵ4 = {
    title: 'Capture Per Diem',
    description: 'Enter Per Diem',
    keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
}, ɵ5 = {
    title: 'Tagessaetze',
    description: 'Tagessaetze',
    keywords: ''
}, ɵ6 = {
    title: 'Expense Table',
    description: 'Manage Expenses',
    keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
}, ɵ7 = {
    title: 'Timekeeper',
    description: 'Track Time',
    keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
}, ɵ8 = {
    title: 'Weekly Timesheet',
    description: 'Timesheet for weekly time',
    keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock-in, Check-In'
}, ɵ9 = {
    title: 'Status',
    description: 'Client Status and settings',
    keywords: ''
}, ɵ10 = {
    title: 'Welcome',
    description: 'Time + Expense Overview',
    keywords: 'Track Time, Timesheet, Timecard, Timekeeper, Clock in/out, Check in/out'
};
const routes = [
    {
        path: 'test',
        component: TestComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        canDeactivate: [LoginDeactivateGuard],
        data: ɵ0
    },
    {
        path: 'time',
        component: TimeComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiTeitemResolver },
        data: ɵ1
    },
    {
        path: 'in-out',
        component: InOutComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiTeitemResolver },
        data: ɵ2
    },
    {
        path: 'expense',
        component: ExpenseComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiTeitemResolver },
        data: ɵ3
    },
    {
        path: 'per-diem',
        component: PerDiemComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiTeitemResolver },
        data: ɵ4
    },
    {
        path: 'per-diem-de',
        component: PerDiemDeComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiTeitemResolver },
        data: ɵ5
    },
    {
        path: 'expense-table',
        component: ExpenseTableComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiTeitemResolver },
        data: ɵ6
    },
    {
        path: 'track',
        component: TimeKeeperComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiTeitemResolver },
        data: ɵ7
    },
    {
        path: 'timesheet',
        component: TimesheetComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiTeitemResolver },
        data: ɵ8
    },
    {
        path: 'ui/:uiTab',
        component: ObjectHomeComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiResolver } // route.params[ 'uiTab' ];
    },
    {
        path: 'ui/:uiTab/:id',
        component: RecordHomeComponent,
        canActivate: [LoginGuard],
        resolve: {
            uiTab: UiResolver,
            record: DataResolver // route.params[ 'id' ];
        }
    },
    {
        path: 'status',
        component: StatusComponent,
        data: ɵ9
    },
    {
        path: '',
        component: DashboardComponent,
        canActivate: [LoginGuard],
        resolve: { uiTab: UiTeitemResolver },
        data: ɵ10
    },
    {
        path: '**',
        redirectTo: '/'
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
