import { Logger } from 'accorto';
import * as d3 from 'd3';

/**
 * Bar Chart (Business) Entry
 */
export class BarChartStackedEntry {

  categoryLabel: string;

  constructor(public date: Date,
              public categoryId: any,
              public value: number,
              public index: number) {
  }

  // category label or id
  get category(): string {
    if (this.categoryLabel) {
      return this.categoryLabel;
    }
    if (this.categoryId) {
      return 'id=' + this.categoryId;
    }
    return '-';
  }

  add(value: number) {
    this.value += value;
  }

  getTime(): number {
    return this.date.getTime();
  }
} // BarChartStackedEntry


/**
 * Stacked Bar Chart Data
 */
export class BarChartStackedData {

  // Chart Label - e.g. expenses
  label: string = 'BarChartStacked';
  // Sub Label - e.g. for week
  subLabel: string = '';
  // Value dimension - e.g. $
  dimensionLabelPrefix: string = '';
  dimensionLabelSuffix: string = '';

  totalLabel: string = 'Total: ';

  /** stack data */
  data: { [ key: string ]: number; }[] = [];
  /** x/y keys w/o category */
  keys: string[] = [ 'date', 'value' ];

  /** value d3 formatter (fixed 2 decimals) - https://github.com/d3/d3-format */
  valueFormat: string = '.2f';
  /** date d3 formatter (day, date month) - https://github.com/d3/d3-time-format */
  dateFormat: string = '%a, %d %b';

  /** categories */
  categoryIds: string[] = [ '-' ]; // initial empty
  categoryLabels: string[] = [ '-' ]; // initial empty

  /** Map key-> entry */
  private entryMap: Map<number, BarChartStackedEntry> = new Map<number, BarChartStackedEntry>();
  /** Total by category */
  private categoryMap: Map<number, number> = new Map<number, number>();
  /** Total by date */
  private dateMap: Map<number, number> = new Map<number, number>();

  private log: Logger = new Logger('BarChartStackedData');

  /**
   * Add Data
   * @param date the date
   * @param categoryId optional category id (e.g. projectId)
   * @param value value to add
   */
  add(date: Date, categoryId: any, value: number) {
    let categoryIndex: number = 0;
    if (categoryId) {
      categoryIndex = this.categoryIds.indexOf(categoryId);
      if (categoryIndex === -1) {
        this.categoryIds.push(categoryId);
        categoryIndex = this.categoryIds.indexOf(categoryId);
      }
    }
    // yyyymmddcc
    const key = categoryIndex
      + (date.getDate() * 100)
      + (date.getMonth() * 10000)
      + (date.getFullYear() * 1000000);
    // this.log.log('add ' + key)();
    let entry: BarChartStackedEntry = this.entryMap.get(key);
    if (entry) {
      entry.add(value);
    } else {
      entry = new BarChartStackedEntry(date, categoryId, value, key);
      this.entryMap.set(key, entry);
    }
  } // add

  /**
   * @param catIndex 1..
   * @return label
   */
  categoryLabel(catIndex: number): string {
    if (catIndex <= 1) {
      return this.categoryLabels[ 0 ]; // -None-
    } else if (catIndex > this.categoryLabels.length) {
      this.log.debug('categoryLabel ' + catIndex, this.categoryLabels, this.categoryIds)();
      if (catIndex <= this.categoryIds.length) {
        return 'id=' + this.categoryIds[ catIndex - 1 ];
      }
      return '-#' + catIndex + '-';
    }
    return this.categoryLabels[ catIndex - 1 ];
  }

  /**
   * Reset all Data
   */
  clear() {
    this.data = [];
    this.categoryIds = [ '-' ];
    this.categoryLabels = [ '-None-' ];
    this.entryMap.clear();
    this.categoryMap.clear();
    this.dateMap.clear();
  }

  /**
   * @param time ms
   * @return formatted date
   */
  dateLabel(time: number): string {
    const theDate = new Date(time);
    const timeFormat = d3.timeFormat(this.dateFormat);
    return timeFormat(theDate);
  }

  /**
   * @param time ms
   * @return total for time
   */
  dateTotalLabel(time: number): string {
    const v = this.dateMap.get(time);
    return this.valueLabel(v);
  }

  dateTotalLabels(): string[] {
    return this.dateTotals().map((v) => {
      return this.valueLabel(v);
    });
  }

  /**
   * Totals per dates
   * @return total per date
   */
  dateTotals(): number[] {
    const values: number[] = [];
    this.dateMap.forEach((v, k) => {
      values.push(v);
    });
    return values;
  }

  /**
   * @param key entry key
   * @param value entry value
   * @return category: value
   */
  entryLabel(key: number, value: number): string {
    const entry: BarChartStackedEntry = this.entryMap.get(key);
    if (entry) {
      return entry.category + ': ' + this.valueLabel(entry.value);
    }
    return this.valueLabel(value); // fallback
  }

  /**
   * Sort, create category, fill data
   */
  process(idLabelMap: Map<string, string>) {
    // this.log.warn('process ' + this.seqNo)();

    // reset result data
    this.data = [];
    this.categoryMap.clear();
    this.dateMap.clear();
    //
    const entries: BarChartStackedEntry[] = [];
    this.entryMap.forEach((value: BarChartStackedEntry) => {
      if (idLabelMap) { // update entry label
        if (value.categoryId) {
          const categoryLabel = idLabelMap[ value.categoryId ];
          if (categoryLabel) {
            value.categoryLabel = categoryLabel;
          }
        } else {
          value.categoryLabel = '-None-';
        }
      }
      if (!value.categoryLabel) {
        value.categoryLabel = '<' + value.categoryId + '>';
      }
      entries.push(value);
    });

    // sort by date (asc) and category (asc)
    entries.sort((o1: BarChartStackedEntry, o2: BarChartStackedEntry) => {
      let cmp = o1.date.getTime() - o2.date.getTime();
      if (cmp === 0) {
        cmp = this.categoryIds.indexOf(o1.categoryId) - this.categoryIds.indexOf(o2.categoryId);
      }
      return cmp;
    });

    // update category labels
    if (idLabelMap) {
      for (let i = 1; i < this.categoryIds.length; i++) {
        if (this.categoryLabels.length < (i - 1)) {
          this.categoryLabels.push(idLabelMap[ this.categoryIds[ i ] ]);
        } else {
          this.categoryLabels[ i ] = idLabelMap[ this.categoryIds[ i ] ];
        }
      }
    }

    // this.log.log('process categories', this.categoryIds, idLabelMap, this.categoryLabels)();
    for (const entry of entries) {
      const categoryIndex = this.categoryIds.indexOf(entry.categoryId || '') + 1;
      this.data.push({
        date: entry.getTime(),
        category: categoryIndex,
        value: entry.value,
        entry: entry.index
      });
      // totals
      const sumCategory = this.categoryMap.get(categoryIndex);
      if (sumCategory) {
        this.categoryMap.set(categoryIndex, sumCategory + entry.value);
      } else {
        this.categoryMap.set(categoryIndex, entry.value);
      }
      const sumDate = this.dateMap.get(entry.getTime());
      if (sumDate) {
        this.dateMap.set(entry.getTime(), sumDate + entry.value);
      } else {
        this.dateMap.set(entry.getTime(), entry.value);
      }
    }
    // this.log.debug('process maps',  this.categoryMap, this.dateMap, this.dateTotalLabels())();
    // this.log.debug('process data', this.data)();
    // this.log.debug('process entries', entries)();
    // this.log.debug('process entryMap', this.entryMap)();
  } // process

  /**
   * @return Grand Total
   */
  total(): number {
    return this.dateTotals().reduce((store, value) => {
      return store + value;
    }, 0);
  }

  /**
   * @param value label value
   * @return formatted value
   */
  valueLabel(value: number): string {
    return this.dimensionLabelPrefix + d3.format(this.valueFormat)(value) + this.dimensionLabelSuffix;
  }

} // BarChartStackedData


/**
 * D3 stack Interface
 */
export interface BarChartStackedDatum {

  // the date
  date: number;
  // index 1..
  category: number;
  // actual value
  value: number;
  // key to map
  entry: number;

} // BarChartStackedDatum
